import './App.css';
import React, { Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainLayout from './components/layouts/mainLayout/MainLayout';
import axios from 'axios';

function App() {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
  return (
      <HashRouter>
        <Suspense fallback={''}>
          <Switch>
            <Route path="/" component={MainLayout} />
          </Switch>
        </Suspense>
      </HashRouter>
  );
}

export default App;
