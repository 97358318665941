import React  from 'react';
import CDBG01 from '../../../images/CD-BG-01.jpg';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Logo from '../../../images/CertifiablyDiverse-logo-min.png';
import Platform from '../../../images/CertifiablyDiverse-Platform-min.png';
import Locator from '../../../images/SupplierLocator_blue-min-1.png';
import Lockup from '../../../images/BuyerSmartLogo_Lockup.png';
import Venpro from '../../../images/icon-venpro-min.png';
import ProSource from '../../../images/Icon-ProSource-min.png';
import Boost from '../../../images/Icon-Boost-min.png';
import Venlytics from '../../../images/Icon-Venyltics-min.png';
import SupplierList from '../../../images/Supplier-List-min.png';
import Stats from '../../../images/Dashboard-Stats-min.png';
import Toolbox from '../../../images/ToolBox-min.png';
import Speed from '../../../images/Spend-min.png';
import Purple from '../../../images/Purple-Bg.png';
import style from './MainPage.module.css';

const useStyles = makeStyles((theme) => ({
  leftGrid: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  rightGrid: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
    },
  },
  line4: {
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '60px',
    },
  },
  images: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '200px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 'none',
    },
  },
}));

const MainPage = () => {
  const classes = useStyles();
  return (
    <div className={'w-100'}>
      <img
        src={CDBG01}
        style={{
          width: '100%',
          height: 'auto',
          zIndex: '-10',
          position: 'absolute',
        }}
        alt=""
      />
      <Container maxWidth={'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <img src={Logo} alt="" />
          </Grid>
          <Grid item xs={12} md={6} className={`${classes.leftGrid} d-flex`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px',
              }}
            >
              <span className={style.line1}>
                For Supplier Diversity Managers
              </span>
              <span className={style.line2}>Harness the</span>
              <span className={style.line3_1}>
                True Power <span className={style.line3_2}>of</span>
              </span>
              <span className={`${style.line4} ${classes.line4}`}>
                Supplier Diversity
              </span>
              <span className={style.line5}>
                Certifiably Diverse is the smartest way to{' '}
                <strong>empower</strong>, <strong>optimize</strong> and{' '}
                <strong>innovate</strong> any-size supplier diversity program
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={`d-flex ${classes.rightGrid}`}>
            <img
              className={classes.images}
              src={Platform}
              style={{ maxWidth: '80%', height: 'max-content' }}
              alt=""
            />
          </Grid>
          <Grid item xs={12} md={6} className={`${classes.leftGrid} d-flex`}>
            <img
              className={classes.images}
              src={Locator}
              style={{ maxWidth: '80%', width: 'auto', height: 'max-content' }}
              alt=""
            />
          </Grid>
          <Grid item xs={12} md={6} className={`d-flex justify-content-center`}>
            <div className={'d-inline'}>
              <p style={{ width: '100%', textAlign: 'center' }}>
                <img
                  className={classes.images}
                  src={Lockup}
                  style={{ width: '300px' }}
                  alt=""
                />
              </p>
              <p className={style.line6}>
                A Beautifully <strong>Designed</strong>, Easy-To-Use{' '}
                <strong>Vendor</strong> Management <strong>Platform</strong>
              </p>
              <p className={style.line7}>
                At the core of the Certifiably Diverse platform is our
                BuyerSmartHub, a single source that intelligently combines four
                powerful components:
              </p>

              <Grid container className={'d-flex'}>
                <Grid item xs={12} sm={6} className={'d-flex'}>
                  <Grid item xs={6} className={'d-flex justify-content-center'}>
                    <img
                      src={Venpro}
                      alt=""
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{ fontSize: '24px' }}>
                      <strong>VenPro</strong>
                    </p>
                    <p>
                      Pre-qualify and vet any supplier while managing
                      certification compliance.
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={'d-flex'}>
                  <Grid item xs={6} className={'d-flex justify-content-center'}>
                    <img
                      src={ProSource}
                      alt=""
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{ fontSize: '24px' }}>
                      <strong>ProSource</strong>
                    </p>
                    <p>
                      Advance supplier sourcing with one-touch smart filters
                      that also target, track and engage.
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={'d-flex'}>
                  <Grid item xs={6} className={'d-flex justify-content-center'}>
                    <img
                      src={Boost}
                      alt=""
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{ fontSize: '24px' }}>
                      <strong>Boost</strong>
                    </p>
                    <p>
                      Supercharge supplier outreach initiatives, marketing and
                      communication.
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={'d-flex'}>
                  <Grid item xs={6} className={'d-flex justify-content-center'}>
                    <img
                      src={Venlytics}
                      alt=""
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{ fontSize: '24px' }}>
                      <strong>Venlytics</strong>
                    </p>
                    <p>
                      Visually track, measure and optimize supplier diversity
                      spend and utilization.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        className={'mt-5 p-5'}
        style={{ background: `url("${Purple}")` }}
      >
        <Grid
          item
          className={'d-flex justify-content-center'}
          xs={12}
          style={{
            fontSize: '50px',
            lineHeight: '50px',
            fontWeight: '700',
            color: 'white',
          }}
        >
          Communicate, Collaborate & Network
        </Grid>
        <Grid
          item
          className={'d-flex justify-content-center'}
          xs={12}
          style={{
            fontSize: '21px',
            lineHeight: '26px',
            fontWeight: '400',
            color: 'white',
          }}
        >
          Certifiably Diverse transforms complex supplier diversity challenges
          into flexible, seamless, and quantifiable data.
        </Grid>
        <Grid container className={'d-flex mt-3'}>
          <Grid item xs={12} md={3} className={'p-3'}>
            <img src={SupplierList} alt="" style={{width: '95%'}}/>
            <p
              style={{
                color: 'white',
                textAlign: 'center',
                marginTop: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Supplier Registration and Certification Expiration Alerts
            </p>
          </Grid>
          <Grid item xs={12} md={3} className={'p-3'}>
            <img src={Stats} alt=""  style={{width: '95%'}}/>
            <p
              style={{
                color: 'white',
                textAlign: 'center',
                marginTop: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Buyer Dashboard At-A-Glance Stats and Real-Time Activity
            </p>
          </Grid>
          <Grid item xs={12} md={3} className={'p-3'}>
            <img src={Toolbox} alt=""  style={{width: '95%'}}/>
            <p
              style={{
                color: 'white',
                textAlign: 'center',
                marginTop: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Supplier Diversity Manager ToolBox to Quickly Communicate
            </p>
          </Grid>
          <Grid item xs={12} md={3} className={'p-3'}>
            <img src={Speed} alt=""  style={{width: '95%'}}/>
            <p
              style={{
                color: 'white',
                textAlign: 'center',
                marginTop: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Supplier Spend At-A-Glance Reporting and Statistical Totals
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ height: '100px', backgroundColor: '#292B32' }}>
        <Container maxWidth={'lg'}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '10px',
              color: '#cccccc',
            }}
          >
            © 2019 Certifiably Diverse. All Rights Reserved.
          </div>
        </Container>
      </Grid>
    </div>
  );
};

export default MainPage;
