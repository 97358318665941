import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainPage from '../../pages/mainPage/MainPage';
import { Nav, Navbar } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

const ContactUs = lazy(() => import('../../pages/contactUs/ContactUs'))

const MainLayout = () => {

  const history = useHistory()

  return (
    <>
      <Navbar collapseOnSelect expand="md" bg="light" variant="light">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="tel:+1-800-260-6970">
              <i className="fad fa-phone" />
              &nbsp;(800)260-6970
            </Nav.Link>
            <Nav.Link
              href="mailto:sales@certifiablydiverse.com"
              target={'_blank'}
            >
              <i className="fad fa-envelope-square" />
              &nbsp;sales@certifiablydiverse.com
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              onClick={() => {
                window.open('https://hub.certifiablydiverse.com', '_blank');
              }}
            >
              Go to Smarthub Portal
            </Nav.Link>
          </Nav>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push('/contactus')}
          >
            Contact Us
          </Button>
        </Navbar.Collapse>
      </Navbar>
      <Suspense fallback={''}>
        <Switch>
          <Route exact path="/" render={() => <MainPage />} />
          <Route exact path="/contactus" render={() => <ContactUs />} />
        </Switch>
      </Suspense>

    </>
  );
};

export default MainLayout;
